import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import { Tour, message } from "antd";
import { Navigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import { BASE_URL } from "./Base";
import axios from "axios";
import menu1 from "../Assets/1.png";
import menu2 from "../Assets/2.png";
import menu3 from "../Assets/5.png";
import menu4 from "../Assets/6.png";
import { notification } from "antd";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
const AuthContext = createContext(null);
export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(Cookies.get("token"));
  const [isEmailVerified, setisEmailVerified] = useState(
    Cookies.get("isEmailVerified")
  );
  const [nav, setNavi] = useState("Dashboard");
  const [messageApi] = message.useMessage();
  const [demo, setDemo] = useState();
  const [errorInput, setErrorInput] = useState(null);
  const [apiload, setAPIload] = useState(false);
  const [apiloadSuper, setAPIloadSuper] = useState(false);
  const [have, sethaveCampus] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [openSeting, setSeting] = useState(false);
  const [visibleCMenu, SetVisible] = useState(false);
  const [selectCAMPUS, SelectCampus] = useState(Cookies.get("campus"));
  const [superDashi, setSuperDashi] = useState();
  const [allCampus, setAllCampus] = useState(null);
  const [Totalsudent, setTotalsudent] = useState(null);
  const [OtherStudent, setComingSoonSudent] = useState(null);
  const [Totaladmin, setTotaladmin] = useState();
  const [Loder, setLoder] = useState("All");
  const [roleSwitch, setRoleSwitch] = useState(Cookies.get("role"));
  const [on, Off] = useState(Cookies.get("boolen"));
  const [userType, setUserType] = useState(Cookies.get("RoleSwitch"));
  const [formDataALLcampus, SetAllcampusformData] = useState({
    searchKey: null,
    Loading: true,
    rollNo: 0,
    fellowshipName: 0,
    owner: 0,
    phone: 0,
    campusLog: 0,
    campusStatus: 0,
    campusStatus_filter:null,
    studentsRegisteredThisYear: 0,
    campusDataEntryProgressPlain: 0,
    rollNoLoader: false,
    fellowshipNameLoader: false,
    ownerLoader: false,
    phoneLoader: false,
    campusLogLoader: false,
    campusStatusLoader: false,
    studentsRegisteredThisYearLoader: false,
    campusDataEntryProgressPlainLoader: false,
    page: '1',
    resultsPerPage:'5',
  });
  const [formDataALLadmin, SetAllAdminForm] = useState({
    searchKey: null,
    Loading: true,
    status_filter:null,
    page: 1,
    resultsPerPage:5,
  });

  const [rout, setRout] = useState("");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const [open, setOpen] = useState(false);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const steps = [
    {
      title: "Where to select Campus?",
      description:
        "Go to side navigation dashboard and click campus dashboard. then you will see campus list there.",
      cover: <img alt="tour.png" src={menu1} />,
      target: () => ref1.current,
    },
    {
      title: "If you have not campus yet?",
      placement: "right",
      description:
        "here is how you create  a campus page . click create button above campus name list. ",
      cover: <img alt="tour.png" src={menu2} />,
      target: () => ref2.current,
    },
    {
      title: "How to Skeep? ",
      placement: "right",
      description:
        "Click number on the steps of a page at the top corner and you can easly move forward and backward. ",
      cover: <img alt="tour.png" src={menu3} />,
      target: () => ref3.current,
    },

    {
      title: "How to see a campus Page",
      description:
        "to see your created campus page go to left side navigation and click it. if you want to see your page demo and other controling option just click three dot's at the top right of your campus page Card.  ",
      cover: <img alt="tour.png" src={menu4} />,
      target: () => ref5.current,
    },
  ];

  const SETCAMPUS = (e) => {
    
    //Cookies.set('campus',e)
    SelectCampus(Cookies.get("campus"));
  };

  const routChange = (route) => {
    setRout(rout);
  };

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleDarkMode = (color) => {
    if (color === "light") {
      setIsDarkMode(false);
      ThemUpdate("light");
    } else {
      setIsDarkMode(true);
      ThemUpdate("dark");
    }
  };
  const HaveCampus = (campus) => {
    sethaveCampus(campus);
  };
  const ToorOpen = () => {
    setOpen(true);
  };

  const ThemUpdate = (color) => {
    SelectCampus(Cookies.get("campus"));

    axios
      .post(`${BASE_URL}/api/set-theme`, {
        theme: color,
      })
      .then((res) => {
        if (res.data.status === 200) {
        } else if (res?.data?.status === 401) {
          //  logout()
        }
      })
      .catch(function () {});
  };
  const ToorClose = () => {
    axios
      .post(`${BASE_URL}/api/mark-tour-as-completed`)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.tour_status === "true") {
            setOpen(!open);
          }
        } else if (res?.data?.status === 401) {
          //  logout()
        }
      })
      .catch(function () {});
  };
  const handleNext = () => {
    //setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    SetAllcampusformData({ ...formDataALLcampus, Loading: true });
    GetAllCampus();
  }, [
    formDataALLcampus.searchKey,
    formDataALLcampus?.rollNo,
    formDataALLcampus?.campusDataEntryProgressPlain,
    formDataALLcampus?.campusLog,
    formDataALLcampus?.campusStatus,
    formDataALLcampus?.fellowshipName,
    formDataALLcampus?.owner,
    formDataALLcampus?.phone,
    formDataALLcampus?.studentsRegisteredThisYear,
    formDataALLcampus?.resultsPerPage,
    formDataALLcampus?.page,
    formDataALLcampus?.campusStatus_filter
  ]);

  const GetAllCampus = () => {
  
    axios
      .post(`${BASE_URL}/api/sort-campus-list`, {
        searchKey: formDataALLcampus.searchKey,
        fellowshipName: formDataALLcampus.fellowshipName,
        owner: formDataALLcampus.owner,
        phone: formDataALLcampus.phone,
        campusLog: formDataALLcampus.campusLog,
        campusStatus: formDataALLcampus.campusStatus,
        studentsRegisteredThisYear:
          formDataALLcampus.studentsRegisteredThisYear,
        campusDataEntryProgressPlain:
          formDataALLcampus.campusDataEntryProgressPlain,
        page: parseInt(formDataALLcampus.page),
        resultsPerPage: parseInt(formDataALLcampus.resultsPerPage),
        campusStatus_filter: formDataALLcampus.campusStatus_filter
      })
      .then((res) => {
     

        if (res.data.status === 200) {
          setAllCampus(res?.data);
          SetAllcampusformData({
            ...formDataALLcampus,
            Loading: false,
            rollNoLoader: false,
            fellowshipNameLoader: false,
            ownerLoader: false,
            phoneLoader: false,
            campusLogLoader: false,
            campusStatusLoader: false,
            studentsRegisteredThisYearLoader: false,
            campusDataEntryProgressPlainLoader: false,
          });
        } else if (res?.data?.status === 401) {
          SetAllcampusformData({
            ...formDataALLcampus,
            Loading: false,
            rollNoLoader: false,
            fellowshipNameLoader: false,
            ownerLoader: false,
            phoneLoader: false,
            campusLogLoader: false,
            campusStatusLoader: false,
            studentsRegisteredThisYearLoader: false,
            campusDataEntryProgressPlainLoader: false,
          });
        }
      })
      .catch(function () {});
  };

  const GetSuperDashi = () => {
    axios
      .get(`${BASE_URL}/api/get-superadmin-dashboard`)
      .then((res) => {
        if (res.data.status === 200) {
          setSuperDashi(res?.data);
        } else if (res?.data?.status === 401) {
          //  logout()
        }
      })
      .catch(function () {});
  };
  const GetComingSoonStudent = () => {
    axios
      .post(`${BASE_URL}/api/get-all-temporary-students`,{
        page:1,
        resultsPerPage:2
      })
      .then((ress) => {
      
        if (ress?.data?.status === 200) {
          setComingSoonSudent(ress?.data);
        } else if (ress?.data?.status === 401) {
          //  logout()
          setComingSoonSudent(null);
        } else {
          setComingSoonSudent(null);
        }
      })
      .catch(function () {});
  };
  const GetAllStudent = () => {
    axios
      .post(`${BASE_URL}/api/get-all-students`)
      .then((ress) => {
     
        if (ress?.data?.status === 200) {
          setTotalsudent(ress?.data);
        } else if (ress?.data?.status === 401) {
          //  logout()
          setTotalsudent(null);
        } else {
          setTotalsudent(null);
        }
      })
      .catch(function () {});
  };
  //for get all admin
useEffect(()=>{
  SetAllAdminForm({ ...formDataALLadmin, Loading: true });
GetAllAdmin();
},[formDataALLadmin.resultsPerPage,formDataALLadmin?.status_filter,formDataALLadmin.page, formDataALLadmin.searchKey])
  const GetAllAdmin = () => {
 
    axios.post(`${BASE_URL}/api/get-all-admins`,
    {
     searchKey: formDataALLadmin.searchKey,
      page:parseInt(formDataALLadmin.page),
resultsPerPage:parseInt(formDataALLadmin.resultsPerPage),
status_filter:formDataALLadmin.status_filter
  })
      .then((ress) => {
      
      console.log(ress)
        if (ress?.data?.status === 200) {
          setTotaladmin(ress?.data);
          SetAllAdminForm({ ...formDataALLadmin,page:formDataALLadmin.page,resultsPerPage:formDataALLadmin.resultsPerPage, Loading: false });
         
        
        } else  {
          
          SetAllAdminForm({ ...formDataALLadmin,page:formDataALLadmin.page,resultsPerPage:formDataALLadmin.resultsPerPage, Loading:false });
        }
      })
      .catch(function () {});
  };

  const toor = () => {
    return (
      <Tour
        open={open}
        nextButtonProps={{
          children: "Next",
          onClick: () => handleNext(),
        }}
        onClose={() => ToorClose()}
        mask={true}
        type="primary"
        steps={steps}
      />
    );
  };

  const APIloading = () => {
    setAPIload(!apiload);
  };
  const APIloadingSuper = () => {
    setAPIloadSuper(!apiloadSuper);
  };
  const key = "updatable";

  const login = (data) => {
    Cookies.set("token", data.token);
    Cookies.set("login", data.message);
    Cookies.set("isEmailVerified", data.isEmailVerified);
    setisEmailVerified(data.isEmailVerified);
    setToken(data.token);
    UserType(data.role);
   if(data.isEmailVerified===false){
    return '/authnotif';
   }else{
    return '/';
   }
    
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("campus");
    Cookies.remove("RoleSwitch");
    Cookies.remove("role");
    setToken(null);
  };
  const navi = (navi) => {
    setNavi(navi);
    setRout(navi);
  };
  const UserType = (role) => {
    if (role === "admin") {
      Cookies.set("RoleSwitch", "Admin");
      Cookies.set("role", "Admin");
      setRoleSwitch(Cookies.get("role"));
      setUserType("Admin");
    
    } else {
      Cookies.set("RoleSwitch", "SuperAdmin");
      setUserType("SuperAdmin");
      Cookies.set("role", "SuperAdmin");
      setLoder("All");
      setRoleSwitch(Cookies.get("role"));
     
     // GetSuperDashi();
      //GetAllCampus();
      //GetAllStudent();
     // GetAllAdmin();
    }
  };
  const SweetchToAdmin = (role) => {
    if (role === false) {
      Cookies.set("RoleSwitch", "Admin");
      setUserType("Admin");
      Cookies.set("boolen", false);
      // setRoleSwitch(false);
      Off(false);
    } else {
      Cookies.set("RoleSwitch", "SuperAdmin");
      setUserType("SuperAdmin");
      Cookies.set("boolen", true);
      // setRoleSwitch(true);
      GetSuperDashi();
      GetAllStudent();
      GetAllAdmin();
      GetAllCampus();
      Off(true);
    }
  };
  const openMessage = (content, types, keys) => {
    api[types]({
      message: types,
      description: (
        <p className="text-sm text-gray-500   whitespace-wrap">{content}</p>
      ),
    });
  };
  return (
    <AuthContext.Provider
      value={{
        formDataALLadmin,
        SetAllAdminForm,
        GetSuperDashi,
        GetAllStudent,
        GetAllAdmin,
        GetAllCampus,
        allCampus,
        Loder,
        setAllCampus,
        setLoder,
        formDataALLcampus,
        SetAllcampusformData,
        setUserType,
        userType,
        UserType,
        on,
        Off,
        APIloadingSuper,
        apiloadSuper,
        superDashi,
        Totaladmin,
        Totalsudent,
        OtherStudent,
        GetComingSoonStudent,
        SweetchToAdmin,
        roleSwitch,
        token,
        rout,
        routChange,
        setSeting,
        openSeting,
        SetVisible,
        visibleCMenu,
        have,
        isDarkMode,
        selectCAMPUS,
        SelectCampus,
        SETCAMPUS,
        ThemUpdate,
        toggleDarkMode,
        ToorOpen,
        ToorClose,
        HaveCampus,
        toor,
        open,
        setOpen,
        steps,
        login,
        logout,
        navi,
        nav,
        openMessage,
        contextHolder,
        errorInput,
        demo,
        setDemo,
        apiload,
        APIloading,
        isEmailVerified,
        setisEmailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
