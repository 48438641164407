import { FaWindowClose } from "react-icons/fa";
export default function RightBar ({children,open,close}){
   
    return(
        <div
        className="absolute right-0 top-0 bg-black bg-opacity-30 backdrop-blur-sm  w-[100%] h-screen modal z-50 ">
<div className="modal-content bg-white w-[30%] absolute right-0 p-5 h-screen">
    <div>
    <FaWindowClose size={20} onClick={()=>close()} color="red" />
        </div>
<p className="mb-5 text-md mt-8"> Filter you can select multiple of option</p>
{children}
</div>
        </div>
    )
}