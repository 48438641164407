import Contener from '../Component/Contener'
import PaginatedItems from '../Component/PaginatedItems'
import ProfileModal from '../Component/ProfileModal'
import RowSelect from '../Component/RowSelect'
import MainRouter from './MainRouter'
import React, { useState, useEffect } from "react";
import horizon from '../Assets/horizon.png'
import Button from '../Component/Button'
import Input from '../Component/Input'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import axios from 'axios';
import { DownOutlined } from '@ant-design/icons';
import {  Empty, message } from 'antd';
import { Menu, Dropdown } from "antd";
import { BASE_URL } from '../Function/Base'
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom'
import { useAuth } from '../Function/AuthProvider'
import ReactPaginate from 'react-paginate'
import { IoFilterOutline } from 'react-icons/io5'
import RightBar from '../Component/RightBar'

function Admin(props) {
    const [open, setOpen] = useState(false);
    const [modalData,setModalData]=useState({});
    const [filter,setFilter]=useState('firstName');
    const [bgimage,setViewprofile]=useState(horizon)
 const [campus,SetCampus]=useState([]);
    const [ids,setID]=useState(null);
    const auth=useAuth()
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
const {campusID} = useParams();
//  useEffect(()=>{
//   auth.GetAllAdmin();
//  },[])
 
    const [messageApi, contextHolder] = message.useMessage();
    function success(info){
      messageApi
        .open({
          type: 'success',
          content: 'Processing',
          duration: 2.5,
        })
        .then(() => message.success(info, 2.5))
      
    };
    function menuAction(id){
setID(id)
    }
    const handleClick = ( id,key, ) => {
      if(key==='Active'){
      
        axios.post(`${BASE_URL}/api/activate-admin?`,{   
        campusID:props.selectedValue,
        adminID:id
      })

        .then(function (response) {
      // handle success   

          if(response.data?.status===200){
         
            auth.openMessage(response.data?.message,'success',1)
            auth.APIloading();
        
          }else{
            auth.openMessage(response.data?.message,'error',1) 
          }
        })
        .catch(function (error) {
          // handle error
          auth.openMessage(error.response.data.message,'error',1)
        })
        .finally(function () {
          // always executed
        });
      }else if(key==='DeActivate'){
        axios.post(`${BASE_URL}/api/deactivate-admin?campusID=${props.selectedValue}&adminID=${id}`)
        .then(function (response) {
      // handle success   
  
          if(response.data?.status===200){
            auth.openMessage(response.data?.message,'success',1)
         auth.APIloading();
     
          }else{
            auth.openMessage(response.data?.message,'error',1)
          }
        })
        .catch(function (error) {
          auth.openMessage(error.response.data.message,'error',1)
        })
        .finally(function () {
          // always executed
        });
      }else if(key==='Delete'){
  
        axios.delete(`${BASE_URL}/api/delete-admin?adminID=${id}&campusID=${props.selectedValue}`)
    .then(function (response) {
  // handle success   

      if(response.data?.status===200){
       
        auth.openMessage(response.data?.message,'success',1)
        auth.APIloading();
      
      }else{
        auth.openMessage(response.data?.message,'error',1)
      }
    })
    .catch(function (error) {
      // handle error
    
      auth.openMessage(error,'error',1)
    })
    .finally(function () {
      // always executed
    });
      }else if(key==='Admin'){
        axios.post(`${BASE_URL}/api/demote-admin?adminID=${id}`)
        .then(function (response) {
        
          if(response?.data?.status===200){
            auth?.openMessage(response?.data?.message,'success',1)
            auth.APIloadingSuper();
       
          }else{
            auth.openMessage(response?.data?.message,'error',1)
          }
        })
        .catch(function (error) {
          auth.openMessage(error.response.data.message,'error',1)
       
        })
        .finally(function () {
          // always executed
        }); 
      }else if(key==='superAdmin'){
        axios.post(`${BASE_URL}/api/promote-admin?adminID=${id}`)
        .then(function (response) {
 
          if(response?.data?.status===200){
            auth?.openMessage(response?.data?.message,'success',1)
            auth.APIloadingSuper();
       
          }else{
            auth?.openMessage(response?.data?.message,'error',1)
          }
        })
        .catch(function (error) {
          auth.openMessage(error.response.data.message,'error',1)
          
        })
        .finally(function () {
          // always executed
        });  
      }else if(key==='block'){
        axios.post(`${BASE_URL}/api/block-admin?adminID=${id}`)
        .then(function (response) {
      // handle success   
 
          if(response.data?.status===200){
            auth?.openMessage(response.data?.message,'success',1)
            auth.APIloadingSuper();
       
          }else{
            auth?.openMessage(response.data?.message,'error',1)
          }
        })
        .catch(function (error) {
          auth.openMessage(error.response.data.message,'error',1)
        })
        .finally(function () {
          // always executed
        });  
      }else if(key==='unblock'){
        axios.post(`${BASE_URL}/api/unblock-admin?adminID=${id}`)
        .then(function (response) {
      // handle success   
 
          if(response.data?.status===200){
            auth?.openMessage(response.data?.message,'success',1)
            auth.APIloadingSuper();
          }else{
            auth?.openMessage(response.data?.message,'error',1)
          }
        })
        .catch(function (error) {
          auth.openMessage(error.response.data.message,'error',1)
        })
        .finally(function () {
          // always executed
        });  
      }
    };
    function menu(id,status,role){
    return(
        <Menu key={id} >
           {props.off==='DA'?<>
           {auth?.roleSwitch&&role==='admin'&& <Menu.Item onClick={()=>handleClick(id,'superAdmin')} key="superAdmin">Promote to Super-Admin</Menu.Item>
           }
          {auth?.roleSwitch&&role!=='admin'&&  <Menu.Item onClick={()=>handleClick(id,'Admin')} key="Admin">Demote to Admin</Menu.Item>
    }
    {auth?.roleSwitch&& status!='active'? <Menu.Item onClick={()=>handleClick(id,'unblock')} key="block">Activate-Admin</Menu.Item>:
           <Menu.Item onClick={()=>handleClick(id,'block')} key="unblock">Deactivate-Admin</Menu.Item>
          
          }        
           </>:
           <>
           {status!='active'?
           <Menu.Item onClick={()=>handleClick(id,'Active')} key="Active">Activate</Menu.Item>
           :
          <Menu.Item onClick={()=>handleClick(id,'DeActivate')} key="DeActive">Deactivate</Menu.Item>
           }
          <Menu.Item onClick={()=>handleClick(id,'Delete')}  key="Delete">Remove</Menu.Item>
           </>
    }
          
        
         
        </Menu>
      );
      
    } 
  
const token=Cookies.get('token');
   const handleRowsPerPageChange = (value,Page,table) => {
   if(table==='Admin'){
    props?.SetAllAdminForm({...props?.formDataAdmin,page:Page,resultsPerPage:value});
   }else{
    props?.SetAllAdminForm({...props?.formDataAllAdmin,page:Page,resultsPerPage:value});
   }
   
  };

 
 
  const [searchQuery, setSearchQuery] = useState('');
 
  
 
  const [formData,setFormData]=useState({
    Firstname:null,
    Lastname:null, 
    Email:null,
    Password:null, 
    campusID:null
  });
function handleSubmit(e){
   e.preventDefault();
setFormData({...formData,[e.target.name]:e.target.value})
}

const newAdmin=()=>{
  setOpen(true);
}

const Invite=()=>{
  if(formData.Email!=null&&formData.Email!=''&&formData.Firstname!=null&formData.Firstname!=''&&formData.Lastname!=null,formData.Lastname!=''&&
  formData.campusID!=null){
    axios.post(`${BASE_URL}/api/invite-admin?`,{
      token:token,
      firstName:formData.Firstname,
      lastName:formData.Lastname,
      email:formData.Email,
      campusID:formData.campusID
    })
    .then(function (response) {
  // handle success   
 
      if(response.data?.status==200){
        auth.openMessage(response.data?.message,'success',1)
  setOpen(false)
  auth.APIloading();
      }else if(response?.status==404){
   
        auth.openMessage(response.data?.message,'error',1)
      }else{
      
        auth.openMessage(response.data?.message,'error',1)
      }
    })
    .catch(function (error) {
      // handle error
      success(error);
    })
    .finally(function () {
      // always executed
    });
  }else{
    auth.openMessage('All field requerd','error',1)
  }

 }
 useEffect(()=>{
  const token=Cookies.get('token')
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.post(`${BASE_URL}/api/view-campuses`)
.then(res=>{
const datas=res?.data;
if(res.data.status===200){

 SetCampus(res?.data?.Active_Campuses);
}
}).catch()
 
},[])

useEffect(()=>{
  auth.setDemo(campusID)
},[campusID])

const handleKeyDown = (event,table,Page) => {
  if (event.keyCode === 13) {
    // Perform search
   
  if(table==='Admin'){

    props?.SetAllAdminForm({...props?.formDataAdmin,page:Page,resultsPerPage:props.resultsPerPage, searchKey:searchQuery===null?null:searchQuery})
  }else{
    props?.SetAllAdminForm({...props?.formDataAllAdmin,page:Page,resultsPerPage:props.resultsPerPage,searchKey:searchQuery===null?null:searchQuery})
  }
}
};
function searchHandle(table,Page){
  
  if(table==='Admin'){
    props?.SetAllAdminForm({...props?.formDataAdmin,page:Page,resultsPerPage:props.resultsPerPage, searchKey:searchQuery===null?null:searchQuery})
  }else{
    props?.SetAllAdminForm({...props?.formDataAllAdmin,page:Page, resultsPerPage:props.resultsPerPage,searchKey:searchQuery===null?null:searchQuery})
  }
}
const [close,setClose]=useState(false);

  return (
   
    <div className={`${props?.isDarkMode?'dark dark:bg-[#000] dark:text-white h-screen overflow-y':'h-screen overflow-y bg-[#F5F5F5]'}`}>

    <div className={`dark:bg-[#000] dark:text-white ${props?.expand===false?'md:ml-[5.5%]  ': 'md:ml-[17.5%]  ' }`}>
     
             {auth?.contextHolder}
            <div className=" bg-white -mt-[1px] md:mt-[20px] mb-5 dark:bg-black dark:text-white">

<div className=" w-full">
<img className='w-full h-[400px] mt-[0px]' src={horizon} alt="" />
</div>

<div className=' flex flex-col  items-center mt-[-10px] justify-center gap-8     h-full daark:bg-black dark:text-white ' >

<div className=" self-center   bg-white w-full m-2 md:w-[90%]  border border-solid dark:bg-black dark:text-white mt-8 md:-mt-[101px] ">
   <div className="flex flex-row justify-between items-center h-[48px]  md:w-full  md:mr-[41px]">
  <div className='flex gap-4 items-center dark:bg-black dark:text-white '>

  <div className=''>
              {close&&
<RightBar close={()=>setClose(!close)} >
<div className="flex flex-col gap-4">
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    name="All"
                    value="all"
                    placeholder="all"
                    onChange={(e)=>props?.SetAllAdminForm({...props.formDataAdmin,status_filter:e.target.value ,page:1, resultsPerPage:props?.resultsPerPage})}
                  />
                  <p>All</p>
                </label>
                <label className="flex gap-2">
                <input type="checkbox" name="active" value="active" 
                    onChange={(e)=>props?.SetAllAdminForm({...props.formDataAdmin,status_filter:e.target.value ,page:1, resultsPerPage:props?.resultsPerPage})}
                  />
                  <p>Active</p>
                </label>
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    name="pending"
                    value="pending"
                    onChange={(e)=>props?.SetAllAdminForm({...props.formDataAdmin,status_filter: e.target.value , page:1, resultsPerPage:props.resultsPerPage})}
                  />
                  <p>Pending</p>
                </label>
                <label className="flex gap-2">
                  <input type="checkbox" name="in active" value="in active"
                    onChange={(e)=>props?.SetAllAdminForm({...props.formDataAdmin,status_filter:e.target.value , page:1,resultsPerPage: props?.resultsPerPage})}
                   />
                  <p>In Active</p>
                </label>
               
              </div>
  </RightBar>
  }
             <div onClick={()=>setClose(!close)} className="flex gap-2 pl-2">
              <p>Filter</p>
              <IoFilterOutline size={20} />
             </div>
             
            </div>

    {/* <select className="md:ml-[16px]  dark:bg-black dark:text-white text-sm md:text-md "  onChange={(e) => setFilter(e.target.value)} > 
        <option value="Filter" className="text-sm md:text-md">Filter</option>
        <option value="active" className="text-sm text-md">Active</option>
        <option value="pending" className=" text-sm text-md">Pending</option>
        <option value="inactive" className=" text-sm text-md">In Active</option>
      
    </select> */}
   
<input type="text" placeholder='Search'   value={searchQuery} onKeyDown={(event)=>handleKeyDown(event,props?.pageinationComponent,parseInt(props?.itemsPerPage))}
        onChange={(e) => setSearchQuery(e.target.value)}  className=' md:w-[500px] h-[30px]  p-3 dark:bg-black dark:hover:bg-white dark:rounded-md dark:text-black bg-white hover:bg-transparent active:bg-transparent'/>
        {searchQuery&&
          <svg onClick={(e)=>searchHandle(props?.pageinationComponent,parseInt(props?.itemsPerPage))} className='dark:text-white hidden md:block' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M14 10.0008C12.9391 10.0008 11.9217 10.4222 11.1716 11.1723C10.4214 11.9225 10 12.9399 10 14.0008C10 15.0616 10.4214 16.079 11.1716 16.8292C11.9217 17.5793 12.9391 18.0008 14 18.0008C15.0609 18.0008 16.0783 17.5793 16.8284 16.8292C17.5786 16.079 18 15.0616 18 14.0008C18 12.9399 17.5786 11.9225 16.8284 11.1723C16.0783 10.4222 15.0609 10.0008 14 10.0008ZM8 14.0008C7.99988 13.0565 8.22264 12.1255 8.65017 11.2835C9.0777 10.4415 9.69792 9.71236 10.4604 9.15529C11.2229 8.59822 12.1061 8.22898 13.0381 8.0776C13.9702 7.92622 14.9249 7.99698 15.8245 8.28412C16.724 8.57126 17.5432 9.06667 18.2152 9.73006C18.8872 10.3935 19.3931 11.2061 19.6919 12.1019C19.9906 12.9977 20.0737 13.9514 19.9343 14.8853C19.795 15.8193 19.4372 16.7072 18.89 17.4768L23.707 22.2938C23.8892 22.4824 23.99 22.735 23.9877 22.9972C23.9854 23.2594 23.8802 23.5102 23.6948 23.6956C23.5094 23.881 23.2586 23.9862 22.9964 23.9884C22.7342 23.9907 22.4816 23.8899 22.293 23.7078L17.477 18.8918C16.5794 19.53 15.5233 19.9089 14.4247 19.9869C13.326 20.0648 12.2271 19.8389 11.2483 19.3337C10.2695 18.8286 9.44869 18.0638 8.87572 17.1231C8.30276 16.1824 7.99979 15.1022 8 14.0008Z" fill="#48535B"/>
         </svg> 
         }
  </div>
   <div className="flex gap-5 items-center">
  {
    props.off!='DA'&&
<Button name='New +'  handlclick={newAdmin} style='w-14 h-[30px] md:w-[97px] md:h-[36px]  text-sm md:text-[14px] mr-4 md:mr-[50px] bg-[#4094F7]' >Invite</Button> 
  
  }
   </div>
    </div> 
    {props?.Loading===true?
    <Skeleton active/>
     :props?.Admin?.length!==0?
 <table key={props?.pageinationComponent} className="w-full">
  <thead>

 
    <tr className="border border-t-solide  h-[48px] hover:bg-gray-100   ">
       
        <td className='flex   hidden md:block'> 

   <div className=" gap-2 p-2 flex flex-col md:flex-row">
    <p className='text-[#6E7C87] text-[14px]'>NO</p>
    <svg  width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>


   </div>
        </td>
        <td>

   <div className="flex gap-2 ">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>FirstName</p>
  
   </div>
        </td>
        <td className=''>

   <div className="flex gap-2">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>LastName</p>
  
   </div>
        </td>
        <td className='hidden md:block'>

   <div className="flex gap-2">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>Phone</p>
  
   </div>
        </td>
        <td className=''>

   <div className="flex gap-2">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>Members</p>
   
   </div>
        </td>
        <td>

   <div className="flex gap-2">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>Log</p>
   
   </div>
        </td>
        <td>

   <div className="flex gap-2">
   <p className='text-[#6E7C87] text-[12px] md:text-[14px]'>Status</p>
   
   </div>
        </td>
        <td className='hidden md:block'></td>
    </tr>
    </thead>
    <tbody>
   {props?.Admin?.length>0&&props?.Admin?.map((data,index)=>(
    <tr key={index} onClick={()=>setViewprofile(`${BASE_URL}/${data?.profilePicture}`)} className="border border-solide h-[40px] hover:bg-gray-100 dark:hover:text-black"  >
    <td className="pl-2  hidden md:block  text-ellipsis overflow-hidden "><p>{data?.rollNo}</p></td>
    <td className="text-[12px] md:text-md text-ellipsis overflow-hidden "><p>{data?.firstName}</p></td>
    <td className="text-[12px] md:text-md text-ellipsis overflow-hidden "><p>{data?.lastName}</p></td>
    <td className="hidden md:block lg:block  text-ellipsis overflow-hidden  text-[12px] md:text-md lg:text-md"><p>{data?.phone}</p></td>
    <td className="text-[12px] md:text-md text-ellipsis overflow-hidden "><p>{data?.campusStatus}{data?.role}</p></td>
    <td className="text-[12px] md:text-md text-ellipsis overflow-hidden "><p>{data?.log}</p></td>
    <td className="">
        <p  className={`rounded-[6px] text-[12px] md:text-[14px] px-2 py-1 md:w-20 text-center  ${data?.status==='inactive'?' text-[#CC0905] bg-[#FFEFEB]':data?.status==='pending'?'text-[#0452C8] bg-[#EBF7FF]':'text-[#119C2B] bg-[#EBFFF1] '}`}>
        {data?.status} 
        </p>
        </td>
    <td className="flex justify-center items-center">
    <Dropdown overlay={()=>menu(data?.id,data?.status,data?.role)} trigger={["click"]}>
    <a key={data?.id}
      className="ant-dropdown-link"
      onClick={() => menuAction(data?.id)}
      style={{ color: "#d46b08", fontWeight: "bold" }}
    >
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 12C8 12.5304 7.78929 13.0391 7.41421 13.4142C7.03914 13.7893 6.53043 14 6 14C5.46957 14 4.96086 13.7893 4.58579 13.4142C4.21071 13.0391 4 12.5304 4 12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10C6.53043 10 7.03914 10.2107 7.41421 10.5858C7.78929 10.9609 8 11.4696 8 12ZM14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12ZM18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10C17.4696 10 16.9609 10.2107 16.5858 10.5858C16.2107 10.9609 16 11.4696 16 12C16 12.5304 16.2107 13.0391 16.5858 13.4142C16.9609 13.7893 17.4696 14 18 14Z" fill="#5B6871"/>
</svg> 
    </a>
</Dropdown>
    </td>
  </tr>
   ))}
   </tbody>
</table> 
:<div className="m-2 mb-8">
<Empty/></div> }
</div>
        </div>
       
        <div className="flex justify-center items-center gap-5 p-5 w-full ">
        <PaginatedItems pageinationComponents={props?.pageinationComponent} 
         formData={props?.pageinationComponent==='Admin'?props?.formDataAdmin:props?.formDataAllAdmin }
          pageCount={props?.pageinationComponent==='Admin'?Math.ceil(props?.pageCountAdmin):Math.ceil(props?.pageCountAllAdmin)}
           resultsPerPage={props?.resultsPerPage} itemsPerPage={props?.itemsPerPage}  items={props?.items}
        
          setItemOffset={props?.pageinationComponent==='Admin'?props?.setItemOffsetAdmin:props?.setItemOffsetAllAdmin} />

      
     
        <RowSelect rowsPerPage={props?.resultsPerPage}  onChange={(value)=>handleRowsPerPageChange(value,props?.itemsPerPage,props?.pageinationComponent)} />
    
        </div>

<ProfileModal width='300' cancel={()=>setOpen(false)} setOpen={setOpen} open={open} title='invite new admin ' Button='Invite' Action={Invite} >
    <div className={`${auth.isDarkMode?'dark dark:bg-[#000] dark:text-white p-3':'bg-[#F5F5F5] p-3'} `}>
       
        <div>
        <div className=''>
      <Input placeholder='  First Name'  lableStyle='authlable'  lable='First Name' name='Firstname' type='text' style='authinput  md:w-[600px] h-[50px] font-plus  invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 '  onChange={(e)=>handleSubmit(e)}/>
      <Input placeholder='  Last Name' lableStyle='authlable'  lable='Last Name' name='Lastname' type='text' style='authinput   md:w-[600px] h-[50px] '  onChange={(e)=>handleSubmit(e)}/>
        </div>
       <div className=''>
      <Input placeholder='  @gmail.com' lableStyle='authlable'  lable='Email' name='Email' type='email' style='authinput   md:w-[600px] h-[50px] font-plus invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 '  onChange={(e)=>handleSubmit(e)}/>
    </div>
    <div className='dark:bg-black dark:text-white'>
      <p className='text-[14px] font-plus authlable mb-2'>Select Campus</p>
      <select className='  text-[14px] font-plus authlable    dark:bg-black dark:text-white  ' name="campusID" id="" onChange={(e)=>handleSubmit(e)}>
        <option className='text-[14px] font-plus authlable' value=''>null </option>
        {campus.length!=0?campus?.map((data,index)=>(
  <option  className='text-[14px] font-plus authlable' key={index} value={data?.About_Section_Contents?.campusID}>{data?.Welcome_Section_Contents?.campusName  }</option>
        )):''}
      
      </select>
    </div>
        </div>
    </div>
  
</ProfileModal>
            </div>
      </div>
      </div>
    
  )
}

export default Admin

