import React, { useEffect, useState } from 'react'
import AuthPage from '../Component/AuthPage'
import { Link,useNavigate,useParams } from "react-router-dom";
import Input from '../Component/Input';
import AuthTitle from '../Component/AuthTitle';
import Button from '../Component/Button';
import { BsCheck2 } from 'react-icons/bs';
import axios from 'axios';
import {  message, Steps, Switch, theme,notification, Spin } from "antd";
import { BASE_URL } from '../Function/Base';
import { useAuth } from '../Function/AuthProvider';
function RestPasswordform(props) {
  const [api, contextHolder] = notification.useNotification();
  const auth=useAuth();
    const navigate = useNavigate();
    const { invKey } = useParams();
    const [formData,setFormData]=useState({     
        Organization:null,
        Email:null,
        Password:null,
        confirmPassword:null,
        resetPassword:true,
      });
      const [isValid, setIsValid] = useState(true);
      const [confirmPassword,setConfirmPassword]=useState(true);
      const [resetP,setReset]=useState(false);
      const passwordRegex =/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    function handleSubmit(e){
       e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value})
      // Regular expression to check for at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8 characters
     
      setIsValid(formData.Password);
     
    }
    function openNotification(placement,message){
      api.info({
        message: `Notification`,
        description:
          message,
        placement,
      });
    }
    const [visible, setVisible] = useState(false);

   
   async function post(){
   
    if(formData.Password!=formData.confirmPassword){
        setConfirmPassword(false);
        auth.openMessage('Passwords do not match!', 'error', 3);
      }else if(formData.Password!=null && isValid!=false){
        setConfirmPassword(true);
       
        await axios.post(`${BASE_URL}/api/reset-password?`,{
         resetKey:invKey,
          newPassword:formData.Password,
          confirmPassword:formData.confirmPassword,
      
        })
        .then(function (response) {
          
          if(response.data?.status===200){
            auth.openMessage(response.data?.message, 'success', 3);
            openNotification('top',response.data?.message)
            navigate('/');
            setFormData({...formData,span:false}) 
        }else if(response.data?.status==403){
           
            auth.openMessage(response.data?.message, 'error', 3);
            setFormData({...formData,span:false}) 
          }else if(response.data?.status==500){
            auth.openMessage(response.data?.message, 'error', 3);
           
            setFormData({...formData,span:false}) 
          }else{
            auth.openMessage(response.data?.message, 'error', 3);
          
          }
        })
        .catch(function (error) {
            openNotification('top',error.response?.statusText);
           
          setFormData({...formData,span:false}) 
        });
      }else{
        setIsValid(false);
        auth.openMessage('Read the rule please!', 'error', 3);
      }
    }
    function  reset(){
      setReset(true)
    }
    function resetBack(){
      setReset(false)
    }
    useEffect(()=>{
    
      
    },[])
    const togglePassword = document.querySelector('.toggle-password');
    const passwordInput = document.querySelector('#password');
    const passwordconfirm=document.querySelector('#Confrimpassword');
    function showPassword() {
      setVisible(!visible);
      const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      const secondType=passwordconfirm.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordconfirm.setAttribute('type', secondType);
    }
  return (
    <div className='md:grid md:grid-cols-2 md:h-screen md:overflow-hidden'>
      <div>
         {auth.contextHolder}
    <AuthPage/>
    </div>
    <div className='flex flex-col justify-center items-center overflow-y h-screen'>
    <AuthTitle title='Rest Now' style='mt-[184px] md:mt-[60px] text-center md:text-start  font-plus'/>
    
 <div className='mt-8 md:mt-[45px] font-plus flex flex-col md:justify-start md:items-start justify-center items-center '>
   
 <p className='mb-[21px] forgetP text-center'><span className='text-[#9009CF] text-[18px]'>You're almost there!</span> <br/>  Please enter a new password for your account.</p>
 <Input placeholder='  ******' id='password' lableStyle='authlable'  lable='New Password' name='Password' type='password' style={` border authinput w-[300px]  md:w-[400px] md:h-[40px] invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${auth.errorInput&& auth.errorInput==='Passwords do not match!'||auth.errorInput==='Password required'?'border-pink-500':''} `}  onChange={(e)=>handleSubmit(e)}/>
 <Input placeholder='  ******' id='Confrimpassword' lableStyle='authlable'  lable='Confirm Password' name='confirmPassword' type='password' style={` border authinput w-[300px]  md:w-[400px] md:h-[40px] invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${auth.errorInput&& auth.errorInput==='Passwords do not match!'||auth.errorInput==='Password required'?'border-pink-500':''} `}  onChange={(e)=>handleSubmit(e)}/>
 <div className=' flex gap-4 justify-start items-center mb-4 ml-24 md:ml-0 w-full'>
     
     <input
         type="checkbox"
         checked={visible}
         onChange={showPassword}
       />
      <label htmlFor="password" className=' text-[12px]'>Show Password</label>
   
     </div>

     {!isValid && <p className='text-[8px] md:text-[8px] opacity-70 mt-[-10px]  '> <p className="  flex items-center text-[8px] md:text-[10px] gap-1 md:gap-2"><BsCheck2 size={10} className='text-red-500' />  Password must be at least <span className="text-red-500">8 characters</span>   <span className="text-red-500">uppercase letters,</span><span className="text-red-500">lowercase letters</span>,and  <span className="text-red-500">numbers</span> </p> <span className="text-red-500 text-[8px]  md:text-[15px]"> </span></p> }      
   <Button name='Reset'  handlclick={post} style='w-[300px]  md:w-[400px] md:h-[40px] h-[40px] mt-[2px] md:mt-[27px]'>Change</Button>
 </div>
 </div>
</div>
  )
}

export default RestPasswordform