import React, { useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAuth } from '../Function/AuthProvider';
function PaginatedItems({ formData,pageinationComponents, setItemOffset, resultsPerPage, itemsPerPage, pageCount }) {
  const [selected, setSelected] = useState({
    adminSelected: 0,
    AlladminSelected: 0,
    campusSelected: 0,
   
  });
  const handlePageClick = (event, table) => {
    const updatedPageNumber = event.selected + 1;

    switch (table) {
      case 'Admin':
        setSelected({ ...selected, adminSelected: updatedPageNumber });
        setItemOffset({ ...formData, page:  updatedPageNumber>pageCount?updatedPageNumber-1:updatedPageNumber, resultsPerPage: resultsPerPage });
       
        break;
      case 'AllAdmin':
        setSelected({ ...selected, AlladminSelected: updatedPageNumber });
         setItemOffset({ ...formData, page:  updatedPageNumber>pageCount?updatedPageNumber-1:updatedPageNumber, resultsPerPage: resultsPerPage });
        // Additional logic specific to Alladmin table...
        break;
      case 'Allcampus':
        setSelected({ ...selected, campusSelected: updatedPageNumber });
         setItemOffset({ ...formData, page:  updatedPageNumber>pageCount?updatedPageNumber-1:updatedPageNumber, resultsPerPage: resultsPerPage });
        // Additional logic specific to campus table...
        break;
      default:
        break;
    }
  };

  return (
    <div className='md:w-[85%]'>
      <ReactPaginate
        className='flex gap-8 justify-center items-center'
        breakLabel="..."
        nextLabel="Next "
        onPageChange={(event) => handlePageClick(event, pageinationComponents)} // Set default as 'admin'
        pageRangeDisplayed={2}
        pageCount={Math.ceil(pageCount)}
        initialPage={pageinationComponents==='Admin'?selected.adminSelected:pageinationComponents==='AllAdmin'?selected.AlladminSelected:pageinationComponents==='Allcampus'?selected.campusSelected:itemsPerPage}
        marginPagesDisplayed={1}
        previousLabel={`Previous`}
        renderOnZeroPageCount={null}
        containerClassName='w-full'
        activeClassName='bg-[#F9F5FF] rounded-[8px] w-[40px] h-[40px] flex justify-center items-center text-[#7F56D9]'
        previousClassName='text-[14px] font-medium font-plus plus-500 lh-20 border border-grey-300  rounded-[8px] w-[86px] h-[36px]  items-center flex justify-center'
        nextClassName='text-[14px]  font-plus plus-500 lh-20 border border-grey-300 rounded-[8px] w-[86px] h-[36px] items-center flex justify-center'
      />
    </div>
  );

// ?? new
// const [currentPage, setCurrentPage] = useState(itemsPerPage);
// const [selectedRow, setSelectedRow] = useState(null);

// // Calculate total pages based on data and itemsPerPage
// const totalPages =  pageCount;
// // Handle page navigation
// const handlePageChange = (newPage) => {
//   setItemOffset({...formData,page:newPage,resultsPerPage:resultsPerPage});
//   setCurrentPage(newPage);
// };

// // Handle row selection
// const handleRowClick = (rowData) => {
//   setSelectedRow(rowData);
// };

// // Render pagination controls
// return (
//   <div className='flex gap-8 justify-center items-center md:w-[85%]'>
//     {/* Previous button (disabled if on first page) */}
//     <button 
//     className='text-[14px] font-medium font-plus plus-500 lh-20 border border-grey-300  rounded-[8px] w-[86px] h-[36px]  items-center flex justify-center'
//     disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//       Previous
//     </button>
//     {/* <div className='flex gap-3'>
// {Array.from({ length: totalPages }, (_, index) => (
//   <button
//  className={`${index + 1 === currentPage?
//   'bg-[#F9F5FF] rounded-[8px] w-[40px] h-[40px] flex justify-center items-center text-[#7F56D9]'
//   :''}`}
//     key={index + 1}
//     onClick={() => handlePageChange(index + 1)}
//     disabled={index + 1 === currentPage}
//   >
//     {index + 1}
//   </button>
// ))}
// </div> */}
//     {/* Page numbers or other indicators */}
//     {/* ... */}

//     {/* Next button (disabled if on last page) */}
//     <button 
//      className='text-[14px]  font-plus plus-500 lh-20 border border-grey-300 rounded-[8px] w-[86px] h-[36px] items-center flex justify-center'
//     disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//       Next
//     </button>

//     {/* Row selector (if applicable) */}
//     {/* ... */}
//   </div>
// );
}

export default PaginatedItems;