import React, { useEffect, useState } from 'react'
import AuthPage from '../Component/AuthPage'
import Button from '../Component/Button'
import Input from '../Component/Input'
import authback from '../Assets/authBack.png'
import '../css/baseStyle.css'
import AuthTitle from '../Component/AuthTitle'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link,useNavigate } from "react-router-dom";
import { useSignIn } from 'react-auth-kit';
import Cookies from 'js-cookie';
import { BASE_URL } from '../Function/Base'
import * as ls from "local-storage";
import { useAuth } from '../Function/AuthProvider'

function Login(props) {
    const navigate = useNavigate();
    const auth = useAuth();
    
    const [remember,setRemember]=useState(false);
    const [formData,setFormData]=useState({
        Email:null,
        Password:null,
        resetPassword:false,
      });
      const url=`${BASE_URL}/api/login?`
      const [isValid, setIsValid] = useState(true);
      const [resetP,setReset]=useState(false);
    function handleSubmit(e){
       e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value})
      // Regular expression to check for at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8 characters
      //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
     // setIsValid(passwordRegex.test(formData.Password));
    }
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
      setVisible(!visible);
    };
    const post = async () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if( emailRegex.test(formData.Email)){
       // setEmail(value);
       if (formData.Email != null && formData.Password!= null&&formData.Password!='') {
         const response = await axios.post(url, {
           email: formData.Email,
           password: formData.Password
          }).catch((error) => {        
            auth.openMessage(error.message, 'error', 2);
        });
   
        if (response?.data?.status === 200) {
          const navigation= auth.login(response.data);
       
         if(response.data.isEmailVerified===false){
          navigate('/authnotif');
         
         }else{
       
           navigate(`${navigation}`);
         }
        } else if ( response?.data?.message) {
          props.sendNoti(response?.data?.message);
         
          auth.openMessage(response?.data?.message, 'error', 1);
        }
      }else{
        auth.openMessage('Password is required!', 'error', 2);
      
      }
    }else{
      auth.openMessage('check email please!', 'error', 2);
      setFormData({...formData,Email:null})
    }
    };
    
    
  //   function  reset(){
  //     setReset(true)
  //   }
  //   function resetBack(){
  //     setReset(false)
  //   }
  //  function Remember(){
  //   setRemember(true);
  //  }
 


function showPassword() {

  setVisible(!visible);
  const passwordInput = document.querySelector('#password');
  const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
  passwordInput.setAttribute('type', type);
 
}

  return (
    <div className='md:grid  md:grid-cols-2 md:h-screen md:overflow-hidden'>
        {auth.contextHolder}
        <div className='md:h-screen'>
       <AuthPage/>
        </div>
        <div className='flex flex-col items-center justify-center'>

      <AuthTitle title='Login' style='mt-[100px] md:ml-8  text-center md:text-start  authheading'/>
      <div className='mt-[25px]   flex flex-col justify-center items-center p-5 md:justify-start md:items-start  font-plus'>
      <Input  placeholder='@gmail.com' lableStyle='authlable' name='Email' lable='Email' type='email' style={`authinput w-[300px]  md:w-[400px] md:h-[50px] h-[40px] font-plus  invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 border  ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='The email must be a valid email address'||auth.errorInput==='The email field is required.'||auth.errorInput==='check email please!'?'border-pink-500':''}`}  onChange={(e)=>handleSubmit(e)}/>
     <div className='relative'>
      <Input  placeholder='******' id='password' lableStyle='authlable' name='Password' lable='Password' type='password' style={` authinput w-[300px] md:w-[400px] md:h-[50px] h-[40px] border ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='The password field is required.'?'border-pink-500':''} `}  onChange={(e)=>handleSubmit(e)}/>
      <div onClick={showPassword} class="absolute inset-y-0 top-4 right-0 pr-3 flex items-center justify-center text-sm leading-5">
  
  </div>
     </div>
      {!isValid && <p className="text-[10px] opacity-70">Password must be at least <span className="text-red-500">8 characters</span> long and contain a combination of <br/> <span className="text-red-500">uppercase letters</span>,<span className="text-red-500">lowercase letters</span>, <span className="text-red-500">numbers</span>, and <span className="text-red-500">special characters</span></p>}
      <div className='flex justify-between -ml-14 md:ml-0 gap-4 md:gap-1 md:justify-none  md:items-center  mb-[40px] text-[12px] font-light '>
      <div className=' flex gap-4 items-center '>
     
     <input
         type="checkbox"
         checked={visible}
         onChange={showPassword}
       />
      <label htmlFor="password" className=' text-[12px]'>Show Password</label>
   
     </div>
        <Link className='md:ml-[180px] text-[12px] underline underline-offset-2 hover:text-[#2eb157]' to='/forgot'>Forgot password</Link>
      </div>
     
      <Button name='Login'    handlclick={post} style=' md:w-[400px] md:h-[50px] w-[300px] h-[40px]'>Login</Button>
      <p className='md:DoAccount mt-[55px] text-[12px]  '>Don't have an account? <Link className='text-[#9009CF] hover:text-[#2eb157] font-plus underline underline-offset-2' to="/signup">Create your account</Link></p>
     
      </div>
        </div>
      
    </div>
  )
}

export default Login